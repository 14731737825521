<template lang='pug'>
.CaseDashboard.main-page.detail-page.pb-0
	loadingOverlay(v-if='loading' message='Loading . . .' :zIndex='999')
	v-row#page-head.d-flex.align-center.justify-md-space-between(v-if='!loading')
		v-col.d-flex.align-center.px-0.pb-0.px-md-2.pb-md-4(cols='12' md='3')
			h1.my-0 {{ $t('CASEDASHBOARD.TITLE') }}
		v-col(cols='12' md='9')
			v-row(no-gutters)
				v-col.pl-3
					v-select(v-model='selectDateMode' :items='dateMode' item-value='index' item-text='name' prepend-inner-icon='mdi-calendar' height='36' flat dense solo @change='searchCases')
				v-col.pl-3(v-if='selectDateMode === 5' cols='4')
					v-row(no-gutters)
						v-col.pr-1(cols='5')
							v-menu(ref="menuStart" v-model="startDayMenu" :z-index='100' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
								template(v-slot:activator="{ on, attrs }")
									v-text-field(v-model='startDay' solo dense flat v-bind="attrs" v-on="on" )
								v-date-picker(v-model='startDay' @input='startDayMenu = false' no-title scrollable @change='searchCases') 
						v-col.pl-1.d-flex.align-center.justify-center(cols='2')
							p.ma-0 － 
						v-col.pl-1(cols='5')
							v-menu(ref="menuEnd" v-model="endDayMenu" :z-index='100' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
								template(v-slot:activator="{ on, attrs }")
									v-text-field(v-model='endDay' solo dense flat v-bind="attrs" v-on="on" )
								v-date-picker(v-model='endDay' @input='endDayMenu = false' no-title scrollable @change='searchCases' :min='startDay' ) 
				v-col.pl-3
					v-select(v-model='process' :items='stageList' item-value='id' item-text='stage_type_name' prepend-inner-icon='icon-step' height='36' flat dense solo @change='searchCases')
				v-col.pl-3
					v-select(v-model='agent' :items='ownerList' item-value='id' item-text='fullName' prepend-inner-icon='icon-group' height='36' flat dense solo @change='searchCases')
				refreshBtn(@EmitRefresh='onEmitRefresh')
	#page-inner(v-if='!loading')
		v-row
			v-col.p-0.pt-4(cols='12' md='12' lg='10')
				v-row
					v-col(cols='12')
						v-row
							v-col(cols='12')
								v-card.card-preface.card-shadow.px-0(flat)
									v-row.py-6
										v-col.px-2.px-md-4.py-0
											v-list-item.d-flex.align-center.px-0(@click='showTable("unsolved")')
												v-list-item-icon.mt-3.mb-0.mr-2
													v-icon(size='28') mdi-close-circle-outline
												v-list-item-content.pa-0
													v-card-subtitle.pa-0.t-lightgary {{ $t('CASEDASHBOARD.UNSOLVED') }}
													.f-text-highlight.mb-0.ellipsis {{ dashboardData.unsolved.total }}
										v-col.px-2.px-md-4.py-0.border-left
											v-list-item.d-flex.align-center.px-0(@click='showTable("overdue")')
												v-list-item-icon.mt-3.mb-0.mr-2
													v-icon(size='28') mdi-clock-alert
												v-list-item-content.pa-0
													v-card-subtitle.pa-0.t-lightgary {{ $t('CASEDASHBOARD.OVERDUE') }}
													.f-text-highlight.mb-0.ellipsis {{ dashboardData.overdue.total }}
										v-col.px-2.px-md-4.py-0.border-left
											v-list-item.d-flex.align-center.px-0(@click='showTable("due_today")')
												v-list-item-icon.mt-3.mb-0.mr-2
													v-icon(size='28') mdi-clock-end
												v-list-item-content.pa-0
													v-card-subtitle.pa-0.t-lightgary {{ $t('CASEDASHBOARD.DUE_TODAY') }}({{ $t('CASEDASHBOARD.CLOSED') }})
													.f-text-highlight.mb-0.ellipsis {{ dashboardData.due_today.total }}
										v-col.px-2.px-md-4.py-0.border-left
											v-list-item.d-flex.align-center.px-0(@click='showTable("open")')
												v-list-item-icon.mt-3.mb-0.mr-2
													v-icon(size='28') mdi-arrow-right-drop-circle-outline
												v-list-item-content.pa-0
													v-card-subtitle.pa-0.t-lightgary {{ $t('CASEDASHBOARD.OPEN') }}
													.f-text-highlight.mb-0.ellipsis {{ dashboardData.open.total }}
										v-col.px-2.px-md-4.py-0.border-left
											v-list-item.d-flex.align-center.px-0
												v-list-item-icon.mt-3.mb-0.mr-2
													v-icon(size='28') mdi-clock
												v-list-item-content.pa-0
													v-card-subtitle.pa-0.t-lightgary {{ $t('CASEDASHBOARD.ON_HOLD') }}
													.f-text-highlight.mb-0.ellipsis {{ dashboardData.on_hold }}
										v-col.px-2.px-md-4.py-0.border-left
											v-list-item.d-flex.align-center.px-0
												v-list-item-icon.mt-3.mb-0.mr-2
													v-icon(size='28') mdi-chevron-up
												v-list-item-content.pa-0
													v-card-subtitle.pa-0.t-lightgary {{ $t('CASEDASHBOARD.HIGH_PRIORITY') }}
													.f-text-highlight.mb-0.ellipsis {{ dashboardData.high_priority }}
										v-col.px-2.px-md-4.py-0.border-left
											v-list-item.d-flex.align-center.px-0
												v-list-item-icon.mt-3.mb-0.mr-2
													v-icon(size='28') mdi-timelapse
												v-list-item-content.pa-0
													v-card-subtitle.pa-0.t-lightgary {{ $t('CASEDASHBOARD.AVERAGE_RESOLVED_TIME') }}
													.f-text-highlight.mb-0.ellipsis {{ dashboardData.average_resolved_time.days }} {{ $t('CASEDASHBOARD.DAY') }} {{ dashboardData.average_resolved_time.hours }} {{ $t('CASEDASHBOARD.HOUR') }} {{ dashboardData.average_resolved_time.mins }} {{ $t('CASEDASHBOARD.MIN') }}
													.f-text-highlight.mb-0.ellipsis(v-if='dashboardData.average_resolved_time_ratio < 0' style='color: green; text-align: right;') {{ $t('CASEDASHBOARD.DOWN') }} {{ Math.abs(dashboardData.average_resolved_time_ratio) }} %
													.f-text-highlight.mb-0.ellipsis(v-else-if='dashboardData.average_resolved_time_ratio > 0' style='color: red; text-align: right;') {{ $t('CASEDASHBOARD.UP') }} {{ dashboardData.average_resolved_time_ratio }} %
							v-col(cols='12' v-if='isShowTable')
								casesTable(:cases='caseData' :case_page='casePage' :itemInPage='10' style='border-top: 2px solid #ecedf3'  @emitDataPage='onEmitDataPage')
					v-col(cols='12')
						v-row
							v-col.px-0(cols='12' md='12' lg='12')
								v-row.d-flex.align-center.justify-space-start
									v-col(cols='12' md='6' lg='3')
										v-card(style='box-shadow: none')
											v-card-title.d-flex {{ $t('CASEDASHBOARD.UNSOLVED') }}
											v-card-text
												v-row.card-line(@click='showTable("unsolved", "VERY_HIGH")' @mouseover='setMouseover("unsolved", 0)' @mouseleave='setMouseleave("unsolved", 0)' :class="{ pointer_row: hoverUnsolved[0] }")
													v-col(cols='auto').d-flex
														v-icon.priority-card(size='20' color='red') fas fa-angle-double-up
														p.f-text-highlight.mb-0.ml-2 {{ $t('CASEPRIORITYLIST.VERY_HIGH') }}
													v-col(cols='auto')
														p.f-text-highlight.mb-0.ml-2 {{ dashboardData.unsolved.priority.very_high }}
												v-row.card-line(@click='showTable("unsolved", "HIGH")' @mouseover='setMouseover("unsolved", 1)' @mouseleave='setMouseleave("unsolved", 1)' :class="{ pointer_row: hoverUnsolved[1] }")
													v-col(cols='auto').d-flex
														v-icon.priority-card(size='20' color='red') fas fa-angle-up
														p.f-text-highlight.mb-0.ml-2 {{ $t('CASEPRIORITYLIST.HIGH') }}
													v-col(cols='auto')
														p.f-text-highlight.mb-0.ml-2 {{ dashboardData.unsolved.priority.high }}
												v-row.card-line(@click='showTable("unsolved", "MEDIUM")' @mouseover='setMouseover("unsolved", 2)' @mouseleave='setMouseleave("unsolved", 2)' :class="{ pointer_row: hoverUnsolved[2] }")
													v-col(cols='auto').d-flex
														v-icon(size='20' color='orange') fas fa-equals
														p.f-text-highlight.mb-0.ml-2 {{ $t('CASEPRIORITYLIST.MEDIUM') }}
													v-col(cols='auto')
														p.f-text-highlight.mb-0.ml-2 {{ dashboardData.unsolved.priority.medium }}
												v-row.card-line(@click='showTable("unsolved", "LOW")' @mouseover='setMouseover("unsolved", 3)' @mouseleave='setMouseleave("unsolved", 3)' :class="{ pointer_row: hoverUnsolved[3] }")
													v-col(cols='auto').d-flex
														v-icon.priority-card(size='20' color='primary') fas fa-angle-down
														p.f-text-highlight.mb-0.ml-2 {{ $t('CASEPRIORITYLIST.LOW') }}
													v-col(cols='auto')
														p.f-text-highlight.mb-0.ml-2 {{ dashboardData.unsolved.priority.low }}
												v-row.mb-2.card-line(@click='showTable("unsolved", "VERY_LOW")' @mouseover='setMouseover("unsolved", 4)' @mouseleave='setMouseleave("unsolved", 4)' :class="{ pointer_row: hoverUnsolved[4] }")
													v-col(cols='auto').d-flex
														v-icon.priority-card(size='20' color='primary') fas fa-angle-double-down
														p.f-text-highlight.mb-0.ml-2 {{ $t('CASEPRIORITYLIST.VERY_LOW') }}
													v-col(cols='auto')
														p.f-text-highlight.mb-0.ml-2 {{ dashboardData.unsolved.priority.very_low }}
									v-col(cols='12' md='6' lg='3')
										v-card(style='box-shadow: none')
											v-card-title.d-flex {{ $t('CASEDASHBOARD.OVERDUE') }}
											v-card-text
												v-row.card-line(@click='showTable("overdue", "VERY_HIGH")'  @mouseover='setMouseover("overdue", 0)' @mouseleave='setMouseleave("overdue", 0)' :class="{ pointer_row: hoverOverdue[0] }")
													v-col(cols='auto').d-flex
														v-icon.priority-card(size='20' color='red') fas fa-angle-double-up
														p.f-text-highlight.mb-0.ml-2 {{ $t('CASEPRIORITYLIST.VERY_HIGH') }}
													v-col(cols='auto')
														p.f-text-highlight.mb-0.ml-2 {{ dashboardData.overdue.priority.very_high }}
												v-row.card-line(@click='showTable("overdue", "HIGH")' @mouseover='setMouseover("overdue", 1)' @mouseleave='setMouseleave("overdue", 1)' :class="{ pointer_row: hoverOverdue[1] }")
													v-col(cols='auto').d-flex
														v-icon.priority-card(size='20' color='red') fas fa-angle-up
														p.f-text-highlight.mb-0.ml-2 {{ $t('CASEPRIORITYLIST.HIGH') }}
													v-col(cols='auto')
														p.f-text-highlight.mb-0.ml-2 {{ dashboardData.overdue.priority.high }}
												v-row.card-line(@click='showTable("overdue", "MEDIUM")' @mouseover='setMouseover("overdue", 2)' @mouseleave='setMouseleave("overdue", 2)' :class="{ pointer_row: hoverOverdue[2] }")
													v-col(cols='auto').d-flex
														v-icon(size='20' color='orange') fas fa-equals
														p.f-text-highlight.mb-0.ml-2 {{ $t('CASEPRIORITYLIST.MEDIUM') }}
													v-col(cols='auto')
														p.f-text-highlight.mb-0.ml-2 {{ dashboardData.overdue.priority.medium }}
												v-row.card-line(@click='showTable("overdue", "LOW")' @mouseover='setMouseover("overdue", 3)' @mouseleave='setMouseleave("overdue", 3)' :class="{ pointer_row: hoverOverdue[3] }")
													v-col(cols='auto').d-flex
														v-icon.priority-card(size='20' color='primary') fas fa-angle-down
														p.f-text-highlight.mb-0.ml-2 {{ $t('CASEPRIORITYLIST.LOW') }}
													v-col(cols='auto')
														p.f-text-highlight.mb-0.ml-2 {{ dashboardData.overdue.priority.low }}
												v-row.mb-2.card-line(@click='showTable("overdue", "VERY_LOW")' @mouseover='setMouseover("overdue", 4)' @mouseleave='setMouseleave("overdue", 4)' :class="{ pointer_row: hoverOverdue[4] }")
													v-col(cols='auto').d-flex
														v-icon.priority-card(size='20' color='primary') fas fa-angle-double-down
														p.f-text-highlight.mb-0.ml-2 {{ $t('CASEPRIORITYLIST.VERY_LOW') }}
													v-col(cols='auto')
														p.f-text-highlight.mb-0.ml-2 {{ dashboardData.overdue.priority.very_low }}
									v-col(cols='12' md='6' lg='3')
										v-card(style='box-shadow: none')
											v-card-title.d-flex {{ $t('CASEDASHBOARD.DUE_TODAY') }}
											v-card-text
												v-row.card-line(@click='showTable("due_today", "VERY_HIGH")' @mouseover='setMouseover("due_today", 0)' @mouseleave='setMouseleave("due_today", 0)' :class="{ pointer_row: hoverDuetoday[0] }")
													v-col(cols='auto').d-flex
														v-icon.priority-card(size='20' color='red') fas fa-angle-double-up
														p.f-text-highlight.mb-0.ml-2 {{ $t('CASEPRIORITYLIST.VERY_HIGH') }}
													v-col(cols='auto')
														p.f-text-highlight.mb-0.ml-2 {{ dashboardData.due_today.priority.very_high }}
												v-row.card-line(@click='showTable("due_today", "HIGH")' @mouseover='setMouseover("due_today", 1)' @mouseleave='setMouseleave("due_today", 1)' :class="{ pointer_row: hoverDuetoday[1] }")
													v-col(cols='auto').d-flex
														v-icon.priority-card(size='20' color='red') fas fa-angle-up
														p.f-text-highlight.mb-0.ml-2 {{ $t('CASEPRIORITYLIST.HIGH') }}
													v-col(cols='auto')
														p.f-text-highlight.mb-0.ml-2 {{ dashboardData.due_today.priority.high }}
												v-row.card-line(@click='showTable("due_today", "MEDIUM")' @mouseover='setMouseover("due_today", 2)' @mouseleave='setMouseleave("due_today", 2)' :class="{ pointer_row: hoverDuetoday[2] }")
													v-col(cols='auto').d-flex
														v-icon(size='20' color='orange') fas fa-equals
														p.f-text-highlight.mb-0.ml-2 {{ $t('CASEPRIORITYLIST.MEDIUM') }}
													v-col(cols='auto')
														p.f-text-highlight.mb-0.ml-2 {{ dashboardData.due_today.priority.medium }}
												v-row.card-line(@click='showTable("due_today", "LOW")' @mouseover='setMouseover("due_today", 3)' @mouseleave='setMouseleave("due_today", 3)' :class="{ pointer_row: hoverDuetoday[3] }")
													v-col(cols='auto').d-flex
														v-icon.priority-card(size='20' color='primary') fas fa-angle-down
														p.f-text-highlight.mb-0.ml-2 {{ $t('CASEPRIORITYLIST.LOW') }}
													v-col(cols='auto')
														p.f-text-highlight.mb-0.ml-2 {{ dashboardData.due_today.priority.low }}
												v-row.mb-2.card-line(@click='showTable("due_today", "VERY_LOW")' @mouseover='setMouseover("due_today", 4)' @mouseleave='setMouseleave("due_today", 4)' :class="{ pointer_row: hoverDuetoday[4] }")
													v-col(cols='auto').d-flex
														v-icon.priority-card(size='20' color='primary') fas fa-angle-double-down
														p.f-text-highlight.mb-0.ml-2 {{ $t('CASEPRIORITYLIST.VERY_LOW') }}
													v-col(cols='auto')
														p.f-text-highlight.mb-0.ml-2 {{ dashboardData.due_today.priority.very_low }}
									v-col(cols='12' md='6' lg='3')
										v-card(style='box-shadow: none')
											v-card-title.d-flex {{ $t('CASEDASHBOARD.OPEN') }}
											v-card-text
												v-row.card-line(@click='showTable("open", "VERY_HIGH")' @mouseover='setMouseover("open", 0)' @mouseleave='setMouseleave("open", 0)' :class="{ pointer_row: hoverOpen[0] }")
													v-col(cols='auto').d-flex
														v-icon.priority-card(size='20' color='red') fas fa-angle-double-up
														p.f-text-highlight.mb-0.ml-2 {{ $t('CASEPRIORITYLIST.VERY_HIGH') }}
													v-col(cols='auto')
														p.f-text-highlight.mb-0.ml-2 {{ dashboardData.open.priority.very_high }}
												v-row.card-line(@click='showTable("open", "HIGH")' @mouseover='setMouseover("open", 1)' @mouseleave='setMouseleave("open", 1)' :class="{ pointer_row: hoverOpen[1] }")
													v-col(cols='auto').d-flex
														v-icon.priority-card(size='20' color='red') fas fa-angle-up
														p.f-text-highlight.mb-0.ml-2 {{ $t('CASEPRIORITYLIST.HIGH') }}
													v-col(cols='auto')
														p.f-text-highlight.mb-0.ml-2 {{ dashboardData.open.priority.high }}
												v-row.card-line(@click='showTable("open", "MEDIUM")' @mouseover='setMouseover("open", 2)' @mouseleave='setMouseleave("open", 2)' :class="{ pointer_row: hoverOpen[2] }")
													v-col(cols='auto').d-flex
														v-icon(size='20' color='orange') fas fa-equals
														p.f-text-highlight.mb-0.ml-2 {{ $t('CASEPRIORITYLIST.MEDIUM') }}
													v-col(cols='auto')
														p.f-text-highlight.mb-0.ml-2 {{ dashboardData.open.priority.medium }}
												v-row.card-line(@click='showTable("open", "LOW")' @mouseover='setMouseover("open", 3)' @mouseleave='setMouseleave("open", 3)' :class="{ pointer_row: hoverOpen[3] }")
													v-col(cols='auto').d-flex
														v-icon.priority-card(size='20' color='primary') fas fa-angle-down
														p.f-text-highlight.mb-0.ml-2 {{ $t('CASEPRIORITYLIST.LOW') }}
													v-col(cols='auto')
														p.f-text-highlight.mb-0.ml-2 {{ dashboardData.open.priority.low }}
												v-row.mb-2.card-line(@click='showTable("open", "VERY_LOW")' @mouseover='setMouseover("open", 4)' @mouseleave='setMouseleave("open", 4)' :class="{ pointer_row: hoverOpen[4] }")
													v-col(cols='auto').d-flex
														v-icon.priority-card(size='20' color='primary') fas fa-angle-double-down
														p.f-text-highlight.mb-0.ml-2 {{ $t('CASEPRIORITYLIST.VERY_LOW') }}
													v-col(cols='auto')
														p.f-text-highlight.mb-0.ml-2 {{ dashboardData.open.priority.very_low }}
								casesTable.m-3(v-if='isShowTablePriority' :cases='caseData', :case_page='casePage' ,:itemInPage='10' @emitDataPage='onEmitDataPage')
					v-col(cols='12')
						v-row
							v-col(cols='12' md='12' lg='4')
								v-card.pa-4(flat)
									h3.mb-2.d-flex.align-center
										//- .cube-icon.small.bg-green.mr-3
										//- 	v-icon(size='19' color='white') mdi-wrench-outline
										span {{ $t('CASEDASHBOARD.PRIORITY') }}
									.d-flex.align-center.justify-center.px-2
										funnel-bar-chart(:chart-data="funnelChartData_priority" :options='funnelChartOptions' :height='250' style='width: 90%')
							v-col(cols='12' md='12' lg='4')
								v-card.pa-4(flat)
									h3.mb-2.d-flex.align-center
										//- .cube-icon.small.bg-green.mr-3
										//- 	v-icon(size='19' color='white') mdi-wrench-outline
										span {{ $t('CASEDASHBOARD.SOURCE') }}
									.d-flex.align-center.justify-center.px-2
										funnel-bar-chart(:chart-data="funnelChartData_source" :options='funnelChartOptions' :height='250' style='width: 90%')
							v-col(cols='12' md='12' lg='4')
								v-card.pa-4(flat)
									h3.mb-2.d-flex.align-center
										//- .cube-icon.small.bg-green.mr-3
										//- 	v-icon(size='19' color='white') mdi-wrench-outline
										span {{ $t('CASEDASHBOARD.GROUP') }}
									.d-flex.align-center.justify-center.px-2
										funnel-bar-chart(:chart-data="funnelChartData_group" :options='funnelChartOptions' :height='250' style='width: 90%')
							v-col(cols='12' md='12' lg='4')
								v-card.pa-4(flat)
									h3.mb-2.d-flex.align-center
										//- .cube-icon.small.bg-green.mr-3
										//- 	v-icon(size='19' color='white') mdi-wrench-outline
										span {{ $t('CASEDASHBOARD.TYPE') }}
									.d-flex.align-center.justify-center.px-2
										funnel-bar-chart(:chart-data="funnelChartData_type" :options='funnelChartOptions' :height='250' style='width: 90%')
							v-col(cols='12' md='12' lg='4')
								v-card.pa-4(flat)
									h3.mb-2.d-flex.align-center
										//- .cube-icon.small.bg-green.mr-3
										//- 	v-icon(size='19' color='white') mdi-wrench-outline
										span {{ $t('CASEDASHBOARD.PROCESS') }}
									.d-flex.align-center.justify-center.px-2
										funnel-bar-chart(:chart-data="funnelChartData_stage" :options='funnelChartOptions' :height='250' style='width: 90%')
							v-col(cols='12' md='12' lg='12')
								v-row.email-card-row.w-100(no-gutters)
									v-card.pa-4.w-100(flat)
										v-row.w-100.d-flex.justify-space-between.mb-2
											v-col(cols='12')
												h3.mb-2.d-flex.align-center
													//- .cube-icon.small.bg-green.mr-3
													//- 	v-icon(size='19' color='white') mdi-wrench-outline
													span {{ $t('CASEDASHBOARD.LINE_CHART') }}
										v-row
											v-col(cols='10')
												.d-flex.align-center.justify-center.px-2
													line-chart(v-if='!isReceivedLine && !isResolvedLine' :chart-data="lineChartData" :options='lineChartOptions' :height='300' style='width: 100%')
													line-chart(v-if='isReceivedLine' :chart-data="receivedLineChartData" :options='lineChartOptions' :height='300' style='width: 100%')
													line-chart(v-if='isResolvedLine' :chart-data="resolvedLineChartData" :options='lineChartOptions' :height='300' style='width: 100%')
											v-col(cols='2')
												v-row(v-click-outside='cancelChoose')
													v-col(cols='12' md='12' lg='12')
														v-card.pa-4(flat @click='cancelChoose' style='border: 2px solid #ecedf3' @mouseover='hoverAll = true' @mouseleave='hoverAll = false' :class="{ pointer_box: hoverAll }")
															h3.d-flex.justify-center.px-2.m-0(style='color: #808080')
																span {{ $t('GENERAL.ALL') }}
													v-col(cols='12' md='12' lg='12')
														v-card.pa-4(flat @click='onLineChart("received")' style='border: 2px solid #ecedf3' @mouseover='hoverReceived = true' @mouseleave='hoverReceived = false' :class="{ pointer_box: hoverReceived }")
															h6.mb-2.d-flex.align-center(style='color: #808080')
																span {{ $t('CASEDASHBOARD.RECEIVED') }}
															h3.d-flex.align-center.justify-center.px-2
																span {{ dashboardData.line_chart_data.received_amount }}
													v-col(cols='12' md='12' lg='12')
														v-card.pa-4(flat @click='onLineChart("resolved")' style='border: 2px solid #ecedf3' @mouseover='hoverResolved = true' @mouseleave='hoverResolved = false' :class="{ pointer_box: hoverResolved }")
															h6.mb-2.d-flex.align-center(style='color: #808080')
																span {{ $t('CASEDASHBOARD.RESOLVED') }}
															h3.d-flex.align-center.justify-center.px-2
																span {{ dashboardData.line_chart_data.resolved_amount }}
			
			v-col.pa-0.pt-4(cols='12' md='12' lg='2')
				v-row
					v-col(cols='12' md='12' lg='12').pl-0
						v-card.most-won-card.h-100.pa-4.w-100(flat)
							h3.mb-6.d-flex.align-center
								.cube-icon.small.bg-primary.mr-3
									v-icon(size='19' color='white') mdi-account
								span {{ $t('CASEDASHBOARD.AGENT_STATUS') }}
							v-list
								div(v-for='(status, key) in agentList')
									v-list-group(color='none')
										template(v-slot:activator)
											v-icon(size='15' v-if='key == "allAgent"').mr-2 fas fa-user
											v-icon(size='15' v-else :color='key == "ONLINE" ? "green" : key == "AWAY" ? "orange" : key == "BUSY" ? "red" : "grey"').mr-2 fas fa-circle
											v-list-item-title(v-if='key == "allAgent"' :title=`$t('CASEDASHBOARD.AGENT_TOTAL')`) {{ $t('CASEDASHBOARD.AGENT_TOTAL') }}
											v-list-item-title(v-else-if='key == "ONLINE"' :title=`$t('AGENTSTATUSLIST.ONLINE')`) {{ $t('AGENTSTATUSLIST.ONLINE') }}
											v-list-item-title(v-else-if='key == "AWAY"' :title=`$t('AGENTSTATUSLIST.AWAY')`) {{ $t('AGENTSTATUSLIST.AWAY') }}
											v-list-item-title(v-else-if='key == "BUSY"' :title=`$t('AGENTSTATUSLIST.BUSY')`) {{ $t('AGENTSTATUSLIST.BUSY') }}
											v-list-item-title(v-else-if='key == "OFFLINE"' :title=`$t('AGENTSTATUSLIST.OFFLINE')`) {{ $t('AGENTSTATUSLIST.OFFLINE') }}
										template(v-slot:appendIcon)
											p.f-text-highlight.mb-0.ml-2 {{ agentList[`${key}`].length }}
										v-list-item.pl-8(v-if='agentList[`${key}`].length != 0' v-for='item, index in agentList[`${key}`]' :key='index')
											v-list-item-avatar(color='aliceBlue' size='40')
												v-icon(v-if="item.usersetting.photo == null") mdi-account
												img(v-else :src='item.usersetting.photo') 
											.t-black.ellipsis {{ item.last_name + ' ' + item.first_name }}
					v-col(cols='12' md='12' lg='12').pl-0
						v-card.most-won-card.h-100.pa-4.w-100(flat)
							h3.mb-2.d-flex.align-center
								.cube-icon.small.bg-pink.mr-3
									v-icon(size='19' color='white') mdi-crown
								span {{ $t('CASEDASHBOARD.CASE_RANKING') }}
							v-list
								v-list-item.px-0(v-for='(item, index) in casesRankingList' :key='index')
									v-list-item-avatar(color="aliceBlue", size="40")
										v-img(v-if='item.photo' :src='item.photo')
										v-icon(v-else) mdi-account
									v-list-item-content
										v-row(no-gutters)
											v-col(cols='8')
												v-chip(:href='onMail(item.email)').mb-1.ellipsis 
													.t-black.ellipsis {{ item.last_name + ' ' + item.first_name }}
												span.t-black.d-block {{ item.case_count }} {{ $t('CASEDASHBOARD.CASES') }}
	v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
		message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
	v-dialog(v-model='successDialog' width='360' content-class='statusDialog')
		success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
	v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
		error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
// dialog
import messageDialog from '@/components/Dialog/messageDialog';
import successDialog from '@/components/Dialog/successDialog';
import errorDialog from '@/components/Dialog/errorDialog';
// loading
import loadingOverlay from "@/components/Common/loadingOverlay";
// data service
import StageDataService from "@/services/StageDataService";
import OrganizationDataService from "@/services/OrganizationDataService";
import dashboardDataService from "@/services/dashboardDataService";
import CaseDataService from "@/services/CaseDataService";
// chart
import funnelBarChart from '../assets/script/horizontalBarChart';
import lineChart from '../assets/script/lineChart';
// component
import casesTable from "@/components/Common/dashbroadCasesTable";
import refreshBtn from "@/components/Common/refreshBtn";
// plug in
import i18n from '/common/plugins/vue-i18n.js' 

export default {
	name: 'Dashboard',
	components: {
		// dialog
		messageDialog,
		successDialog,
		errorDialog,
		// loading
		loadingOverlay,
		// chart
		funnelBarChart,
		lineChart,
		// component
		casesTable,
		refreshBtn
	},
	data() {
		return {
			loading: false,
			startDayMenu: false,
			startDay: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			endDayMenu: false,
			endDay: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			selectDateMode: 0,
			dateMode: [
				{ index: 0, name: i18n.t('DASHBOARD.TODAY') },
				{ index: 1, name: i18n.t('DASHBOARD.THIS_YEAR') },
				{ index: 2, name: i18n.t('DASHBOARD.THIS_SEASON') },
				{ index: 3, name: i18n.t('DASHBOARD.THIS_MONTH') },
				{ index: 4, name: i18n.t('DASHBOARD.THIS_WEEK') },
				{ index: 5, name: i18n.t('DASHBOARD.SELECT_DATE') },
			],
			process: {},
			stageList: [],
			agent: {},
			ownerList: [],
			casesRankingList: [],
			caseData: [],
			casePage:0,
			key: "",
			level: "",
			isShowTable: false,
			isShowTablePriority: false,
			hoverUnsolved: [false, false, false, false, false],
			hoverOverdue: [false, false, false, false, false],
			hoverDuetoday: [false, false, false, false, false],
			hoverOpen: [false, false, false, false, false],
			hoverReceived: false,
			hoverResolved: false,
			hoverAll: false,
			// main data
			dashboardData: {},
			// Dialog
			messageDialog: false,
            message: '',
            msgDialogSituation: '',
            successDialog: false,
            errorDialog: false,
            errorMessage: '',

			funnelChartData_priority: {
				labels: [i18n.t('CASEPRIORITYLIST.VERY_HIGH'), i18n.t('CASEPRIORITYLIST.HIGH'), i18n.t('CASEPRIORITYLIST.MEDIUM'), i18n.t('CASEPRIORITYLIST.LOW'), i18n.t('CASEPRIORITYLIST.VERY_LOW')],
				datasets: [
					{
						data: [],
						backgroundColor: ['rgb(240,89,117)', 'rgb(240,89,117)', 'rgb(242,160,65)', 'rgb(81,139,242)', 'rgb(81,139,242)'],
					},
				]
			},
			funnelChartData_source: {
				labels: [i18n.t('CASESOURCELIST.EMAIL'), i18n.t('CASESOURCELIST.WEB_FORM'), i18n.t('CASESOURCELIST.WEB_CHAT'), i18n.t('CASESOURCELIST.PHONE'), 'Line', 'WhatsApp', i18n.t('CASESOURCELIST.STORE'), i18n.t('CASESOURCELIST.OUTBOUND'), i18n.t('CASESOURCELIST.OTHERS')],
				datasets: [
					{
						data: [],
						backgroundColor: 'rgb(81,139,242)',
					},
				]
			},
			funnelChartData_group: {
				labels: [],
				datasets: [
					{
						data: [],
						backgroundColor: 'rgb(242,160,65)',
					},
				]
			},
			funnelChartData_type: {
				labels: [],
				datasets: [
					{
						data: [],
						backgroundColor: 'rgb(0, 170, 96)',
					},
				]
			},
			funnelChartData_stage: {
				labels: [],
				datasets: [
					{
						data: [],
						backgroundColor: 'rgb(240,89,117)',
					},
				]
			},
			funnelChartOptions: {
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					xAxes: [{
						ticks: {
							beginAtZero: true,
							precision: 0,
							callback: function(value) {
								if(value < 1000 ){
									return value;
								}
								else if(value >= 1000 && value <= 1000000){
									return value / 1000 + ' K';
								}
								else if(value >= 1000000 ){
									return value / 1000000 + ' M';
								}
							}
						}
					}],
				},
				tooltips: {
					callbacks: {
						label: function(value){
							let amount = value.value.toString().split('.');
							amount[0] = amount[0].replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
							return amount.join('.');
						}
					}
				},
				plugins: {
					legend: false
				}
			},
			lineChartData: {
				labels: [],
				datasets: [
					{
						label: i18n.t('CASEDASHBOARD.RECEIVED'),
						data: [],
						fill: false,
						borderColor: 'rgb(81,139,242)',
						backgroundColor: 'rgb(81,139,242)',
						tension: 0.1,
					},
					{
						label: i18n.t('CASEDASHBOARD.RESOLVED'),
						data: [],
						fill: false,
						borderColor: 'rgb(242,160,65)',
						backgroundColor: 'rgb(242,160,65)',
						tension: 0.1,
					},
				]
			},
			receivedLineChartData: {
				labels: [],
				datasets: [
					{
						label: i18n.t('CASEDASHBOARD.RECEIVED'),
						data: [],
						fill: false,
						borderColor: 'rgb(81,139,242)',
						backgroundColor: 'rgb(81,139,242)',
						tension: 0.1,
					},
				]
			},
			resolvedLineChartData: {
				labels: [],
				datasets: [
					{
						label: i18n.t('CASEDASHBOARD.RESOLVED'),
						data: [],
						fill: false,
						borderColor: 'rgb(242,160,65)',
						backgroundColor: 'rgb(242,160,65)',
						tension: 0.1,
					},
				]
			},
			lineChartOptions: {
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					yAxes: [{
						ticks: {
							beginAtZero: true,
							precision: 0,
							callback: function(value) {
								if(value < 1000 ){
									return value;
								}
								else if(value >= 1000 && value <= 1000000){
									return value / 1000 + 'K';
								}
								else if(value >= 1000000 ){
									return value / 1000000 + 'M';
								}
							}
						}
					}]
				},
				tooltips: {
					callbacks: {
						label: function(value){
							let amount = value.value.toString().split('.');
							amount[0] = amount[0].replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
							return amount.join('.');
						}
					}
				}
			},
			isReceivedLine: false,
			isResolvedLine: false,
			agentList: null,
		}
	},
	async created(){
		this.loading = true;

		await StageDataService.stageList("case")
		.then(response => {
			this.stageList = response.data;
			let data = {
				id: "all",
				stage_type_name: i18n.t('GENERAL.ALL')
			}
			this.stageList.unshift(data)
			this.process = this.stageList[0];
		})
		.catch(error => {
			this.showErrorDialog(error);
		});

		await OrganizationDataService.getchangeowner('cases')
		.then(response => {
			this.ownerList = response.data;
			this.ownerList.forEach(agent => {
				agent.fullName = agent.first_name != "" ? agent.last_name + " " + agent.first_name : agent.last_name;
			});
			let data = {
				id: "all",
				fullName: i18n.t('GENERAL.ALL')
			}
			this.ownerList.unshift(data);
			this.agent = this.ownerList[0];
		})
		.catch(error => {
			this.showErrorDialog(error);
		});

		await dashboardDataService.getCaseDashboardData("all", "all", this.selectDateMode)
		.then(response => {
			this.dashboardData = response.data;

			this.funnelChartData_priority.datasets[0].data = this.dashboardData.case_by_priority.data;
			this.funnelChartData_source.datasets[0].data = this.dashboardData.case_by_source.data;

			this.funnelChartData_group.labels = this.dashboardData.case_by_group.label;
			this.funnelChartData_group.datasets[0].data = this.dashboardData.case_by_group.data;

			this.funnelChartData_type.labels = this.dashboardData.case_by_type.label;
			this.funnelChartData_type.datasets[0].data = this.dashboardData.case_by_type.data;

			this.funnelChartData_stage.labels = this.dashboardData.case_by_stage.label;
			this.funnelChartData_stage.datasets[0].data = this.dashboardData.case_by_stage.data;

			this.lineChartData.labels = this.dashboardData.line_chart_data.label;
			this.receivedLineChartData.labels = this.dashboardData.line_chart_data.label;
			this.resolvedLineChartData.labels = this.dashboardData.line_chart_data.label;

			this.lineChartData.datasets[0].data = this.dashboardData.line_chart_data.received;
			this.lineChartData.datasets[1].data = this.dashboardData.line_chart_data.resolved;
			this.receivedLineChartData.datasets[0].data = this.dashboardData.line_chart_data.received;
			this.resolvedLineChartData.datasets[0].data = this.dashboardData.line_chart_data.resolved;

			this.casesRankingList = this.dashboardData.top_5_agent;
		})
		.catch(error => {
			this.showErrorDialog(error);
		})

		await CaseDataService.getAgentList()
		.then(response => {
			this.agentList = response.data;
		})
		.catch(response => {	
			this.showErrorDialog(response);
		})

        await (this.loading = false);
    },
    methods: {
		// dialog
		showMessageDialog(message) {
            this.messageDialog = true;
            this.message = message;
        },
        onEmitMessageDialog() {
            this.messageDialog = false;
        },
        showSuccessDialog() {
            this.successDialog = true;
        },
        onEmitSuccessDialog() {
            this.successDialog = false;
        },
        showErrorDialog(message) {
            this.errorDialog = true;
            this.errorMessage = message;
        },
        onEmitErrorDialog() {
            this.errorDialog = false;
        },
		// common function
        onMail: function (mail) {
            return 'mailto:'+mail;
        },
        getFormatTime(data){
            let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',hour: '2-digit',minute: '2-digit',hour12: false };
            let today  = data;
            return today.toLocaleDateString("zh-TW", options);                
        },
		// search case
		async searchCases() {
			this.loading = true;
			
			let agent = "";
			if (typeof(this.agent) == "object") {
				agent = this.agent.id;
			} else {
				agent = this.agent;
			}

			let process = "";
			if (typeof(this.process) == "object") {
				process = this.process.id;
			} else {
				process = this.process;
			}

			await dashboardDataService.getCaseDashboardData(agent, process, this.selectDateMode, this.startDay, this.endDay)
			.then(response => {
				this.dashboardData = response.data;
				this.funnelChartData_priority.datasets[0].data = this.dashboardData.case_by_priority.data;
				this.funnelChartData_source.datasets[0].data = this.dashboardData.case_by_source.data;

				this.funnelChartData_group.labels = this.dashboardData.case_by_group.label;
				this.funnelChartData_group.datasets[0].data = this.dashboardData.case_by_group.data;

				this.funnelChartData_type.labels = this.dashboardData.case_by_type.label;
				this.funnelChartData_type.datasets[0].data = this.dashboardData.case_by_type.data;

				this.funnelChartData_stage.labels = this.dashboardData.case_by_stage.label;
				this.funnelChartData_stage.datasets[0].data = this.dashboardData.case_by_stage.data;

				this.lineChartData.labels = this.dashboardData.line_chart_data.label;
				this.receivedLineChartData.labels = this.dashboardData.line_chart_data.label;
				this.resolvedLineChartData.labels = this.dashboardData.line_chart_data.label;

				this.lineChartData.datasets[0].data = this.dashboardData.line_chart_data.received;
				this.lineChartData.datasets[1].data = this.dashboardData.line_chart_data.resolved;
				this.receivedLineChartData.datasets[0].data = this.dashboardData.line_chart_data.received;
				this.resolvedLineChartData.datasets[0].data = this.dashboardData.line_chart_data.resolved;

				this.casesRankingList = this.dashboardData.top_5_agent;
			})
			.catch(error => {
				this.showErrorDialog(error);
			});

			await (this.loading = false);
		},
		showTable(key, level, page, sort) {
			if(!page)
			{
					if (this.key == key && this.level == level) {
					if (this.isShowTable == true) {
						this.isShowTable = false;
						return;
					}
					else if (this.isShowTablePriority == true) {
						this.isShowTablePriority = false;
						return;
					}
				}
			}

			this.key = key;
			this.level = level;

			let agent = "";
			if (typeof(this.agent) == "object") {
				agent = this.agent.id;
			} else {
				agent = this.agent;
			}

			let process = "";
			if (typeof(this.process) == "object") {
				process = this.process.id;
			} else {
				process = this.process;
			}

			dashboardDataService.getCaseTableData(agent, process, key, level,page,sort)
			.then(response => {
				this.caseData = response.data.list;
				this.casePage = response.data.total_page;
				if (level == undefined) {
					this.isShowTable = true;
					this.isShowTablePriority = false;
				}
				else {
					this.isShowTable = false;
					this.isShowTablePriority = true;
				}
			});
		},
		onEmitDataPage(page,sort){
			this.showTable(this.key, this.level, page,sort)
		},
		onLineChart(key) {
			if (key == "received") {
				this.isReceivedLine = true;
				this.isResolvedLine = false;
			} else if (key == "resolved") {
				this.isResolvedLine = true;
				this.isReceivedLine = false;
			}
		},
		cancelChoose() {
			this.isReceivedLine = false;
			this.isResolvedLine = false;
		},
		setMouseover(key, index) {
			if (key == "unsolved") {
				this.$set(this.hoverUnsolved, index, true);
			} else if (key == "overdue") {
				this.$set(this.hoverOverdue, index, true);
			} else if (key == "due_today") {
				this.$set(this.hoverDuetoday, index, true);
			} else if (key == "open") {
				this.$set(this.hoverOpen, index, true);
			}
		},
		setMouseleave(key, index) {
			if (key == "unsolved") {
				this.$set(this.hoverUnsolved, index, false);
			} else if (key == "overdue") {
				this.$set(this.hoverOverdue, index, false);
			} else if (key == "due_today") {
				this.$set(this.hoverDuetoday, index, false);
			} else if (key == "open") {
				this.$set(this.hoverOpen, index, false);
			}
		},
		async onEmitRefresh(){
			// get agent status
			this.loading = true;
			await CaseDataService.getAgentList()
			.then(response => {
				this.agentList = response.data;
			})
			.catch(response => {	
				this.showErrorDialog(response);
			})
			// get cases data
			this.searchCases();
		},
    },	
    watch: {

	},
}
</script>

<style>
	.pointer_row {
		cursor: pointer;
		background-color: #ecedf3;
		border-radius: 5px;
	}
	.pointer_box {
		background-color: #ecedf3 !important;
	}
</style>